import Vue from 'vue';
import Router from 'vue-router';
import paths from './paths';
import store from '../store';

Vue.use(Router);
const router = new Router({
  base: '/',
  linkActiveClass: 'active',
  routes: paths
});

let path = '/';

router.beforeEach((to, from, next) => {
  next();
});

export default router;
