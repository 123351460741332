import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import vuetify from "./plugins/vuetify";
import router from "./router/";
import http from "./plugins/http";
import publicHttp from "./plugins/publicHttp";
import rules from "@/api/rules";
import { Ripple } from 'vuetify/lib/directives'
import { mapGetters } from "vuex";

import "./plugins/vuetify";
import "./components/components"
import "./theme/default.sass";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";

Vue.config.productionTip = false;
Vue.prototype.$http = http;
Vue.prototype.$publicHttp = publicHttp;
Vue.prototype.$rule = rules;

window.dbg = (data) => {
  console.debug(data)
  return data;
}

const token = localStorage.getItem("token");
if (token) http.defaults.headers.common["Authorization"] = "Bearer " + token;

new Vue({
  router,
  store,
  vuetify,
  mapGetters,
  directives: {
    Ripple,
  },
  render: h => h(App)
}).$mount("#app");
