<template>
  <v-dialog v-model="dialog" max-width="400px" scrollable persistent>
    <v-card>
      <v-card-text class="pb-0">
        <v-alert type="error" text class="mb-0 d-flex justify-center">{{ !!globalErrorMessage?.message ? globalErrorMessage.message.message : globalErrorMessage.message}}</v-alert>
        <v-card-actions>
          <v-btn block color="primary" text @click="close">OK</v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'ErrorDialog',
    data: () => ({
      dialog: false,
      message: {}
    }),
    props: {
      globalErrorMessage: {type: Object},
    },
    methods: {
      close() {
        this.$emit('close')
        this.dialog = false
      }
    },
    watch: {
      globalErrorMessage(newVal) {
        if(newVal.message) {
          this.dialog = true
        }
      }
    },
  }
</script>

<style scoped>
  .alert-text {
    font-size: 16px;
    text-align: center;
  }

  img {
    height: 200px;
    opacity: 0.8;
    margin: 20px;
  }
</style>