<template>
  <div id="appRoot">
    <template v-if="!$route.meta.public">
      <v-app id="inspire" class="app">
        <app-drawer/>
        <app-toolbar @side-icon-click="handleDrawerVisible"/>
        <v-main>
          <router-view/>
        </v-main>
      </v-app>
    </template>
    <template v-else>
      <transition>
        <keep-alive>
          <router-view :key="$route.fullpath"/>
        </keep-alive>
      </transition>
    </template>
    <error-dialog :global-error-dialog="globalErrorDialog"
                  :global-error-message="globalErrorMessage"
                  @click="setGlobalErrorMessage"
    />
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import AppDrawer from "@/components/AppDrawer";
import AppToolbar from "@/components/AppToolbar";

export default {
  components: {
    AppDrawer,
    AppToolbar,
  },
  data: () => ({
    snackbar: {
      show: false,
      text: "",
      color: ""
    }
  }),
  computed: {
    ...mapGetters(['globalErrorDialog', 'globalErrorMessage', 'errorMessages'])
  },
  watch: {
    width() {
      this.setWindowWidth(this.width);
    }
  },
  methods: {
    ...mapActions(['setGlobalErrorMessage', 'init']),
    getWidth() {
      window.onresize = () => {
        this.width = document.body.clientWidth;
      };
    },
    handleDrawerVisible() {
      this.showDrawer = !this.showDrawer
    },
  },
  created() {
    let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    document.getElementsByTagName('head')[0].appendChild(link);

    this.width = document.body.clientWidth;
    this.init()

    window.getApp = this;
    this.$http.interceptors.response.use(
        response => {
          return response;
        },
        err => {
          return new Promise((resolve, reject) => {
            if (err?.response?.status === 401 || err?.response?.status === 403) {
              this.$store.dispatch("logout");
              this.$router.push("/login");
            } else {
              reject(err);
            }
          });
        }
    );
  },
};
</script>
