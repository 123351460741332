export default [
    {
        path: "*",
        meta: {
            public: true
        },
        redirect: {
            path: "/404"
        }
    },
    {
        path: "/404",
        meta: {
            public: true
        },
        name: "NotFound",
        component: () => import(`@/views/error/NotFound.vue`)
    },
    {
        path: "/403",
        meta: {public: true},
        name: "AccessDenied",
        component: () => import(`@/views/error/Deny.vue`)
    },
    {
        path: "/500",
        meta: {public: true},
        name: "ServerError",
        component: () => import(`@/views/error/Error.vue`)
    },
    {
        path: "/login",
        meta: {public: true},
        name: "Login",
        component: () => import(`@/views/Login.vue`)
    },
    {
        path: "/page",
        meta: {public: true},
        name: "page",
        component: () => import(`@/views/page.vue`)
    },
    {
        path: "/",
        meta: {},
        name: "Root",
        redirect: {
            name: "Home"
        }
    },
    {
        path: "/home",
        meta: {breadcrumb: true},
        name: "Home",
        component: () => import(`@/views/Home.vue`)
    },
    {
        path: "/permission",
        meta: {breadcrumb: true},
        name: "permission",
        component: () =>
            import(
                `@/views/Permission.vue`)
    },
    {
        path: "/roles",
        meta: {breadcrumb: true},
        name: "roles",
        component: () =>
            import(
                `@/views/Roles.vue`)
    },
    {
        path: "/users",
        meta: {breadcrumb: true},
        name: "users",
        component: () =>
            import(
                `@/views/Users.vue`)
    },
    {
        path: "/requests",
        meta: {breadcrumb: true},
        name: "requests",
        component: () =>
            import(
                `@/views/Requests.vue`)
    },
    {
        path: "/invite",
        meta: {breadcrumb: true},
        name: "invite",
        component: () =>
            import(
                `@/views/Invite.vue`)
    },
    {
        path: "/stat",
        meta: {breadcrumb: true},
        name: "stat",
        component: () =>
            import(
                `@/views/Stat.vue`)
    },
];
