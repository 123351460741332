import axios from 'axios';

const http = axios.create({
  baseURL:
    process.env.NODE_ENV === 'production'
      ? '/'
      : 'http://localhost:8081/',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: 180000
});


export default http;
