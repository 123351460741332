import Vue from "vue";
import CustomForm from "@/components/CustomForm";
import ConfirmDialog from "@/components/ConfirmDialog";
import Search from "@/components/Search";
import DialogForm from "@/components/DialogForm";
import ErrorDialog from "@/components/ErrorDialog";

Object.entries({
    CustomForm,
    ConfirmDialog,
    Search,
    DialogForm,
    ErrorDialog
}).forEach(entry => Vue.component(entry[0], entry[1]))