 <template>
  <v-dialog v-model="dialog" :persistent="persistent" :max-width="maxWidth" scrollable>
    <template v-slot:activator="{on}">
      <slot name="activator" v-bind="{on}"/>
    </template>
    <v-card>
      <v-card-title>
        <v-spacer/>
        <span class="headline">{{ title(formModel) }}</span>
        <v-spacer/>
      </v-card-title>

      <v-card-text class="py-4">
        <custom-form
            v-model="valid"
            :model="formModel"
            :fields="fields"
            @change="setFormModel"
        >
          <template v-for="(_, slot) in $scopedSlots" v-slot:[slot]="props">
            <slot :name="slot" v-bind="props"/>
          </template>
        </custom-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer/>
        <v-btn :disabled="!valid || !externalValid" :loading="acceptButtonLoading" text @click="confirmed"
               color="primary">{{ btnTitles.confirmed }}
        </v-btn>
        <v-btn color="primary" :disabled="acceptButtonLoading" @click="declined">{{ btnTitles.declined }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
    import CustomForm from "./CustomForm";

    export default {
        name: "DialogForm",
        components: {
            CustomForm,
        },
        props: {
            value: {
                type: Boolean,
                default: false,
            },
            btnTitles: {
                type: Object,
                required: true,
            },
            persistent: {
                type: Boolean,
                default: false,
            },
            model: {
                type: Object,
            },
            title: {
                type: Function,
                default: model => 'Title',
            },
            maxWidth: {
                type: String,
                default: '600px',
            },
            fields: {
                type: Object,
                required: true,
            },
            closeOnAccept: {
                type: Boolean,
                default: true,
            },
            acceptButtonLoading: {
                type: Boolean,
            },
            externalValid: {
                type: Boolean,
                default: true
            }
        },
        data: () => {
            return {
                valid: false,
                datePikers: {},
            }
        },
        methods: {
            confirmed() {
                if (this.closeOnAccept) {
                    this.dialog = false;
                }
                this.$emit('confirmed', this.formModel);
            },
            declined() {
                this.dialog = false;
                this.formModel = {};
                this.$emit('declined');
            },
            setFormModel(model) {
                this.formModel = model;
            }
        },
        computed: {
            dialog: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('input', value)
                }
            },
            formModel: {
                get() {
                    return this.model
                },
                set(value) {
                    this.$emit('change', value)
                }
            }
        },
    }
</script>

<style scoped>

</style>