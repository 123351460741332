<template>
  <v-navigation-drawer
      :dark="$vuetify.dark"
      :mini-variant.sync="mini"
      permanent
      app
  >

    <v-list-item class="px-2">
      <v-list-item-avatar>
        <v-img src="static/images/logo.jpg"/>
      </v-list-item-avatar>
      <v-list-item-title>{{user.username}}</v-list-item-title>
      <v-btn icon @click.stop="mini = !mini">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
    </v-list-item>
    <v-divider/>
    <v-list dense nav>
      <v-list-item to="home">
        <v-list-item-title>
          <v-icon class="mr-4">home</v-icon>
          Главная
        </v-list-item-title>
      </v-list-item>
      <template v-for="(screen, i) in menus">
        <v-list-item v-for="(view, j) in screen.permissions" :key="`view-${view.description}`" :to="view.view">
          <v-icon  class="mr-4">{{ view.icon }}</v-icon>
          <v-list-item-title>{{ view.description }}</v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
    <template v-slot:append>
      <div class="pa-2">
        <v-btn block color="primary" @click="logout" class="pa-0">
          {{ mini ? '' : 'Logout' }}
          <v-icon v-if="mini">
            logout
          </v-icon>
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import {mapGetters} from "vuex";

export default {
  name: "app-drawer",
  components: {
    VuePerfectScrollbar,
  },
  props: {
    expanded: {
      type: Boolean,
      default: true,
    },
    drawWidth: {
      type: [Number, String],
      default: '350',
    },
    value: Boolean,
  },
  data: () => ({
    mini: false,
    menus: null,
    scrollSettings: {
      maxScrollbarLength: 160
    }
  }),
  computed: {
    ...mapGetters(['user']),
    active() {
      return views => !!views.find(v => ('/' + v.view) === this.$route.path)
    },
  },

  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$router.push("/login");
    },
  },
  mounted() {
    if (localStorage.getItem('token')) {
      this.$http
          .get("/menu")
          .then(response => {
                this.menus = response.data;
              },
              e => this.$store.dispatch('setGlobalErrorMessage', e.response.data))
    }
  }
};
</script>

<style>

</style>
