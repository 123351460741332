import Vue from "vue";
import Vuetify from "vuetify/lib";
import 'material-design-icons-iconfont/dist/material-design-icons.css'

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    dark: localStorage.getItem('dark-theme') === 'true', // getItem возвращает строку,
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: "#0b0a0f",
        secondary: "#272a33",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        black: "#222222",
        grey: "#f3f3f3",
        white: "#ffffff",
      },
      dark: {
        primary: "#0b0a0f",
        secondary: "#272a33",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        black: "#222222",
        grey: "#272727",
        white: "#424242",
      }
    }
  }
});
