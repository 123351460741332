<template>
  <v-form ref="form" v-model="valid" :disabled="disabled" :readonly="readonly">
    <v-container :class="dense ? 'ma-0' : null" fluid>
      <v-progress-linear :active="loading" indeterminate/>
      <v-row v-show="!loading || !!value.hide" :dense="dense">
        <v-col v-for="[key, value] of Object.entries(fields)" v-if="!value.hidden"
               :key="key"
               :cols="value.cols || (value.lg || value.md || value.sm || value.xl || value.xs ? undefined : 12)"
               class="d-flex align-center"
               :lg="value.lg"
               :md="value.md"
               :sm="value.sm"
               :xl="value.xl"
               :xs="value.xs"
        >
          <slot v-if="!value.type" :name="key" v-bind="{ key: key, item: value } "></slot>
          <v-text-field
              outlined
              v-else-if="((value.type.name === String.name) && value.area !== true) || value.type.name === Number.name"
              :value="getter(key)"
              @input="setter(key, $event)"
              :disabled="value.disabled"
              :readonly="value.readonly"
              :dense="dense"
              :hint="value.hint"
              :append-icon="value.appendIcon"
              :append-outer-icon="value.appendOuterIcon"
              :prepend-icon="value.prependIcon"
              :prepend-inner-icon="value.prependInnerIcon"
              :placeholder="value.placeholder"
              @click:append="callCallback(value.append)"
              @click:append-outer="callCallback(value.appendOuter)"
              @click:prepend="callCallback(value.prepend)"
              @click:prepend-inner="callCallback(value.prependInner)"
              :hide-details="value.hideDetails"
              :label="value.label || key"
              :persistent-hint="value.persistentHint"
              :rules="(value.type.name === Number.name ? [ s => !isNaN(s) || 'Числовое поле' ] : []).concat(value.rules || [])"
              :type="!!value.password ? 'password' : (value.type.name === Number.name ? 'number' : 'text')"
          >
            <template v-slot:prepend v-if="!!value.info">
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" >
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                <div style="width: 200px">
                  {{ value.info }}
                </div>
              </v-tooltip>
            </template>
            <template v-slot:append-outer v-if="!!value.buttonAppend">
              <v-btn small color="primary" :disabled="value.disabled" @click="clickAppendOuterButton(key)">
                {{!!value.buttonText ? value.buttonText : 'кнопка'}}
              </v-btn>
            </template>
          </v-text-field>
          <v-textarea
              outlined
              v-else-if="(value.type.name === String.name) && value.area === true"
              :value="getter(key)"
              @input="setter(key, $event)"
              :disabled="value.disabled"
              :readonly="value.readonly"
              :dense="dense"
              :label="value.label || key"
              :rules="value.rules"
              :append-icon="value.appendIcon"
              :append-outer-icon="value.appendOuterIcon"
              :prepend-icon="value.prependIcon"
              :prepend-inner-icon="value.prependInnerIcon"
              :outlined="value.outlined"
              :hide-details="value.hideDetails"
              @click:append="callCallback(value.append)"
              @click:append-outer="callCallback(value.appendOuter)"
              @click:prepend="callCallback(value.prepend)"
              @click:prepend-inner="callCallback(value.prependInner)"
          >
            <template v-slot:prepend v-if="!!value.info">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                {{ value.info }}
              </v-tooltip>
            </template>
            <template v-slot:append-outer v-if="!!value.buttonAppend">
              <v-btn small color="primary" :disabled="value.disabled" @click="clickAppendOuterButton(key)">
                {{!!value.buttonText ? value.buttonText : 'кнопка'}}
              </v-btn>
            </template>
          </v-textarea>
          <v-autocomplete
              outlined
              v-else-if="value.type.name === Array.name"
              :value="getter(key)"
              @input="setter(key, $event)"
              @change="setter(key, $event)"
              :disabled="value.disabled"
              :readonly="value.readonly"
              :chips="value.multiple"
              :clearable="value.clearable !== false"
              :deletable-chips="value.multiple"
              :dense="dense"
              :hint="value.hint"
              :item-text="value.text"
              :item-value="value.value"
              :items="value.items"
              :label="value.label || key"
              :multiple="value.multiple"
              :persistent-hint="value.persistentHint"
              :hide-details="value.hideDetails"
              :return-object="value.returnObject === undefined || value.returnObject"
              :rules="value.rules"
              :append-icon="value.appendIcon"
              :append-outer-icon="value.appendOuterIcon"
              :prepend-icon="value.prependIcon"
              :prepend-inner-icon="value.prependInnerIcon"
              @click:append="callCallback(value.append)"
              @click:append-outer="callCallback(value.appendOuter)"
              @click:prepend="callCallback(value.prepend)"
              @click:prepend-inner="callCallback(value.prependInner)"
              no-data-text="no data"
              :small-chips="value.multiple"
              :search-input.sync="syncSearch[key]"
              @keyup="updSyncSearch(key)"
              @blur="updSyncSearchOnBlur(key)"
          >
            <template v-slot:prepend v-if="!!value.info">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                <div style="width: 200px">
                  {{ value.info }}
                </div>
              </v-tooltip>
            </template>
            <template v-slot:append v-if="!!value.buttonAppend">
              <v-btn small color="primary" :disabled="value.disabled" @click="clickAppendOuterButton(key)">
                {{!!value.buttonText ? value.buttonText : 'кнопка'}}
              </v-btn>
            </template>
          </v-autocomplete>
          <span class="d-flex align-center"
                v-else-if="value.type.name === Boolean.name">
            <v-btn icon class="mx-1" v-if="value.prependIcon"
                   @click="callCallback(value.prepend)">
              <v-icon size="24">{{ value.prependIcon }}</v-icon>
            </v-btn>
            <v-switch
                v-if="value.switch === true"
                :dense="dense"
                :disabled="value.disabled"
                :readonly="value.readonly"
                :label="value.label || key"
                :rules="value.rules"
                :input-value="getter(key)"
                @change="setter(key, $event)"
            />
            <v-checkbox
                v-else
                :dense="dense"
                :disabled="value.disabled"
                :readonly="value.readonly"
                :label="value.label || key"
                :rules="value.rules"
                :input-value="getter(key)"
                @change="setter(key, $event)"
            />
            <v-btn icon class="mx-1" v-if="value.appendOuterIcon"
                   @click="callCallback(value.appendOuter)">
              <v-icon size="24">{{ value.appendOuterIcon }}</v-icon>
            </v-btn>
          </span>
          <v-dialog
              v-else-if="value.type.name === Date.name && /* xor */ !value.date !== !value.time"
              :ref="key"
              v-model="datePikers[key]"
              max-width="300px"
              offset-x
              persistent
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  outlined
                  :value="getter(key)"
                  @input="setter(key, $event)"
                  :disabled="value.disabled"
                  v-bind="attrs"
                  v-on="on"
                  :dense="dense"
                  :label="value.label || key"
                  :rules="value.rules"
                  prepend-icon="mdi-calendar"
                  readonly
              >
                <template v-slot:prepend v-if="!!value.info">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on">
                        mdi-help-circle-outline
                      </v-icon>
                    </template>
                    <div style="width: 200px">
                      {{ value.info }}
                    </div>
                  </v-tooltip>
                </template>

                <template  v-slot:append-outer>
                  <v-btn
                      icon class="mx-1"
                      v-if="value.appendOuterIcon"
                      @click="callCallback(value.appendOuter)">
                    <v-icon size="24">{{ value.appendOuterIcon }}</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </template>
            <v-date-picker
                v-if="value.date && datePikers[key]"
                :ref="key"
                v-model="date[key]"
                color="primary"
                :first-day-of-week="1"
            >
              <v-btn
                  color="primary"
                  text
                  @click="() => { datePikers[key] = false, date[key] = getter(key) }"
              >
                Close
              </v-btn>
              <v-btn
                  color="primary"
                  @click="saveDate(key, date[key])"
              >
                OK
              </v-btn>
            </v-date-picker>
            <v-time-picker
                v-else-if="value.time && datePikers[key]"
                :ref="key"
                :value="getter(key)"
                @input="setter(key, $event)"
                color="primary"
                format="24hr"
                scrollable
                use-seconds
                :first-day-of-week="1"
            >
              <v-spacer/>
              <v-btn
                  color="primary"
                  text
                  @click="() => { datePikers[key] = false }"
              >
                Close
              </v-btn>
              <v-btn
                  color="primary"
                  @click="datePikers[key] = false"
              >
                OK
              </v-btn>
            </v-time-picker>
          </v-dialog>
          <datetime-picker
              v-else-if="value.type.name === Date.name && value.date && value.time"
              :hide-details="value.hideDetails"
              :value="getter(key)"
              @input="setter(key, $event)"
              :dense="dense"
              :label="value.label || key"
              :rules="value.rules"
              :time-picker-props="{format: '24hr'}"
              clear-text="Close"
              date-format="yyyy-MM-dd"
              return-string
              time-format="HH:mm:ss"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import DatetimePicker from "./DatetimePicker"

export default {
  name: "CustomForm",
  components: {
    DatetimePicker,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    model: {
      type: Object,
    },
    fields: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: true,
    }
  },
  data: () => {
    return {
      datePikers: {},
      syncSearch: {},
      date: {}
    }
  },
  methods: {
    saveDate(key, value) {
      this.setter(key, value)
      this.datePikers[key] = false
    },
    clickAppendOuterButton(key) {
      this.$emit('appnedOuterButtonClick-' + key.replace(/\./g, ""), true)
    },
    updSyncSearch(key) {
      this.$emit('syncSearch-' + key.replace(/\./g, ""), this.syncSearch[key])
    },
    updSyncSearchOnBlur(key) {
      this.$emit('syncSearch-' + key.replace(/\./g, ""), null)
    },
    callCallback(callback) {
      callback?.()
    },
    setter(path, value) {
      const topObj = this.formModel;
      let obj = topObj;
      let subPathes = path.split('.');
      let subPath = subPathes[0]
      for (let i = 0; i < subPathes.length; i++) {
        subPath = subPathes[i];
        let subObj = obj[subPath]
        if (!subObj) {
          subObj = obj[subPath] = {}
        }
        if (i < subPathes.length - 1) {
          obj = subObj
        }
      }
      obj[subPath] = value;
      this.formModel = {...topObj}
    }
  },
  computed: {
    valid: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    formModel: {
      get() {
        return this.model || {}
      },
      set(value) {
        this.$refs.form.validate()
        this.$emit('change', value)
      }
    },
    getter() {
      return path => {
        let obj = this.formModel
        if (!obj) return;

        for (let subPath of path.split('.')) {
          const subObj = obj[subPath]
          if (subObj === null || subObj === undefined) { // не делать !subObj
            return;
          }
          obj = subObj
        }
        return obj
      }
    },
  },
  created() {
    let fields = Object.entries(this.fields)
    for (let i = 0; i < fields.length; i++) {
      if (fields[i][1].type === Array) {
        this.syncSearch[fields[i][0]] = null
      }
    }
  }

}
</script>

<style scoped>

</style>