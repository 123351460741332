<template>
  <v-app-bar app color="primary" dark height="57.1">
    <v-spacer/>
    <v-toolbar-items>
      <v-btn icon large text @click="changeTheme">
        <v-icon>
          {{this.$vuetify.theme.dark ? 'dark_mode' : 'light_mode'}}
        </v-icon>
      </v-btn>
    </v-toolbar-items>
  </v-app-bar>
</template>
<script>

export default {
  name: "AppToolbar",
  components: {},
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
    changeTheme() {
      localStorage.setItem('dark-theme', this.$vuetify.theme.dark = !this.$vuetify.theme.dark)
    },
  },
};
</script>